import React, { useState, useEffect } from 'react';
import { ThemedProps } from '../../../styles/theme';
import styled from 'styled-components';
import ReactMapboxGl from 'react-mapbox-gl';
import AddressesList from './addresses-list/addresses-list';
import Marker from '../../marker';
import Popup from '../../popup';
import { FitBounds } from 'react-mapbox-gl/lib/map';
import PopupContent from '../../popup-content';
import { Address } from '../../../pages/contact';

const INIT_FIT_BOUNDS: FitBounds = [
    [17.35332800073742, 51.612234962513575],
    [17.86867642074486, 51.97984501740655],
];

const Container = styled.div<ThemedProps>`
    position: relative;
    height: 80vh;
    width: 100vw;

    ${(props: ThemedProps) => props.theme.mediaQueries.mobile} {
        position: relative;
        height: 70vh;
        width: 100vw;
    }
`;

const countCenterCoordinates = (coordinatesList: [number, number][]): [number, number] => {
    const coordinatesListSum = coordinatesList.reduce(
        (acc, value) => {
            return [acc[0] + value[0], acc[1] + value[1]];
        },
        [0, 0],
    );

    return [coordinatesListSum[0] / coordinatesList.length, coordinatesListSum[1] / coordinatesList.length];
};

const countBounds = (coordinatesList: [number, number][]): FitBounds => {
    const initBounds = {
        minLng: Number.MAX_VALUE,
        maxLng: Number.MIN_VALUE,
        minLat: Number.MAX_VALUE,
        maxLat: Number.MIN_VALUE,
    };

    const bounds = coordinatesList.reduce((acc, coordinates) => {
        const [lng, lat] = coordinates;

        return {
            minLng: lng <= acc.minLng ? lng : acc.minLng,
            maxLng: lng > acc.maxLng ? lng : acc.maxLng,
            minLat: lat <= acc.minLat ? lat : acc.minLat,
            maxLat: lat > acc.maxLat ? lat : acc.maxLat,
        };
    }, initBounds);

    const boundsFactor = ((bounds.maxLat - bounds.minLat + (bounds.maxLng - bounds.minLng)) / 2) * 0.3;

    return [
        [bounds.minLng - boundsFactor, bounds.minLat - boundsFactor],
        [bounds.maxLng + boundsFactor, bounds.maxLat + boundsFactor],
    ];
};

const useContactMap = (addresses: Address[]) => {
    const [selectedAddress, setSelectedAddress] = useState<Address>();
    const [centerCoordinates, setCenterCoordinates] = useState<[number, number]>();
    const [fitBounds, setFitBounds] = useState<FitBounds>();

    useEffect(() => {
        const coordinatesList = addresses.map((address) => address.coordinates);
        const tempCenterCoordinates = countCenterCoordinates(coordinatesList);
        const tempFitBounds = countBounds(coordinatesList);

        setCenterCoordinates(tempCenterCoordinates);
        setFitBounds(tempFitBounds);
    }, [addresses]);

    return {
        centerCoordinates,
        selectedAddress,
        setSelectedAddress,
        fitBounds: fitBounds || INIT_FIT_BOUNDS,
    };
};

const Map = ReactMapboxGl({
    accessToken: `${process.env.GATSBY_MAPBOX_TOKEN}`,
    boxZoom: true,
});

interface Props {
    addresses: Address[];
}

const ContactMap: React.FC<Props> = ({ addresses }: Props) => {
    const { selectedAddress, setSelectedAddress, fitBounds } = useContactMap(addresses);

    return (
        <Container>
            <Map
                fitBounds={fitBounds}
                center={selectedAddress?.coordinates}
                zoom={[16]}
                containerStyle={{
                    height: '100%',
                    width: '100%',
                }}
                style="mapbox://styles/mapbox/light-v10"
            >
                {addresses.map((address) => (
                    <Marker
                        key={address.id}
                        coordinates={address.coordinates}
                        onClick={() => setSelectedAddress(address)}
                    />
                ))}
                {selectedAddress && (
                    <Popup coordinates={selectedAddress.coordinates}>
                        <PopupContent address={selectedAddress} />
                    </Popup>
                )}
            </Map>
            <AddressesList
                selectedAddressId={selectedAddress?.id}
                addresses={addresses}
                setSelectedAddress={setSelectedAddress}
            />
        </Container>
    );
};

export default ContactMap;
