import React from 'react';
import styled from 'styled-components';
import { Address } from './contact/contact-map/addresses-list/addresses-list';
import { ThemedProps } from '../styles/theme';
import IconDirection from '../static/icons/icon-direction.inline.svg';
import IconPhone from '../static/icons/icon-phone.inline.svg';

const CustomIconDirection = styled(IconDirection)<ThemedProps>`
    width: 100%;
    height: 100%;
    fill: ${(props: ThemedProps) => props.theme.colors.yellow};
`;

const CustomIconPhone = styled(IconPhone)<ThemedProps>`
    width: 100%;
    height: 100%;
    fill: ${(props: ThemedProps) => props.theme.colors.yellow};
`;

const IconHref = styled.a<ThemedProps>`
    border-radius: ${({ theme }: ThemedProps) => theme.borders.radiusSmall};
    width: 2rem;
    height: 2rem;

    &:hover {
        background-color: ${({ theme }: ThemedProps) => theme.colors.lightGray};
    }
`;

const IconDataContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const DayDataCell = styled.td`
    padding-right: 1rem;

    ${({ theme }: ThemedProps) => theme.mediaQueries.mobile} {
        font-size: ${({ theme }: ThemedProps) => theme.typography.sizes.small};
    }
`;

const OpneHoursDataCell = styled.th<ThemedProps>`
    font-weight: ${({ theme }: ThemedProps) => theme.typography.weights.bold};
    text-align: center;

    ${({ theme }: ThemedProps) => theme.mediaQueries.mobile} {
        font-size: ${({ theme }: ThemedProps) => theme.typography.sizes.small};
    }
`;

const HoursDataCell = styled.td`
    text-align: center;
`;

const showHours = (hours?: [string, string]) => {
    if (hours) {
        const [start, end] = hours;
        return `${start}-${end}`;
    } else {
        return '-';
    }
};

interface Props {
    address: Address;
}

const PopupContent: React.FC<Props> = ({ address }: Props) => {
    return (
        <div>
            <table>
                <thead>
                    <tr>
                        <OpneHoursDataCell colSpan="2">Godziny otwarcia</OpneHoursDataCell>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <DayDataCell>Pon</DayDataCell>
                        <HoursDataCell>{showHours(address.hours.mon)}</HoursDataCell>
                    </tr>
                    <tr>
                        <DayDataCell>Wt</DayDataCell>
                        <HoursDataCell>{showHours(address.hours.tue)}</HoursDataCell>
                    </tr>
                    <tr>
                        <DayDataCell>Śr</DayDataCell>
                        <HoursDataCell>{showHours(address.hours.wen)}</HoursDataCell>
                    </tr>
                    <tr>
                        <DayDataCell>Czw</DayDataCell>
                        <HoursDataCell>{showHours(address.hours.thu)}</HoursDataCell>
                    </tr>
                    <tr>
                        <DayDataCell>Pt</DayDataCell>
                        <HoursDataCell>{showHours(address.hours.fri)}</HoursDataCell>
                    </tr>
                    <tr>
                        <DayDataCell>Sob</DayDataCell>
                        <HoursDataCell>{showHours(address.hours.sat)}</HoursDataCell>
                    </tr>
                    <tr>
                        <DayDataCell>Niedz</DayDataCell>
                        <HoursDataCell>{showHours(address.hours.sun)}</HoursDataCell>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan="2">
                            <IconDataContainer>
                                <IconHref href={address.googleUrl}>
                                    <CustomIconDirection />
                                </IconHref>
                                <IconHref href={`tel:${address.phoneNumber}`}>
                                    <CustomIconPhone />
                                </IconHref>
                            </IconDataContainer>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    );
};

export default PopupContent;
