import React from 'react';
import { Popup as MapboxPopup } from 'react-mapbox-gl';

interface Props {
    coordinates: [number, number];
    children: React.ReactNode;
}

const Popup: React.FC<Props> = ({ coordinates, children }: Props) => {
    return (
        <MapboxPopup
            style={{
                zIndex: 0,
                paddingBottom: '2.5rem',
            }}
            // offset={[0, -45]}
            coordinates={coordinates}
        >
            {children}
        </MapboxPopup>
    );
};

export default Popup;
