import React from 'react';
import styled from 'styled-components';
import { ThemedProps } from '../../../../styles/theme';
import { Address } from '../../../../pages/contact';

const Container = styled.div`
    position: absolute;
    top: 3rem;
    left: 3rem;
    max-height: calc(100% - 6rem);
    background-color: ${(props: ThemedProps) => props.theme.colors.white};
    border-radius: ${(props: ThemedProps) => props.theme.borders.radius};
    box-shadow: ${({ theme }: ThemedProps) => theme.effects.shadow};
    display: flex;
    flex-flow: column nowrap;
    overflow-y: auto;

    ${({ theme }: ThemedProps) => theme.mediaQueries.mobile} {
        padding: 2rem;
        bottom: 1rem;
        left: 0;
        right: 0;
        top: unset;
        background-color: unset;
        border-radius: unset;
        box-shadow: unset;

        ::-webkit-scrollbar {
            display: none;
        }
    }
`;

const List = styled.ul`
    display: flex;
    flex-flow: column nowrap;
    list-style: none;
    padding: 0;
    margin: 0;

    ${({ theme }: ThemedProps) => theme.mediaQueries.mobile} {
        flex-flow: row nowrap;
    }
`;

const Heading = styled.h3`
    color: ${(props: ThemedProps) => props.theme.colors.darkgray};
    margin: 1rem 1rem;
    text-align: center;

    ${({ theme }: ThemedProps) => theme.mediaQueries.mobile} {
        display: none;
    }
`;

interface AddressItemProps extends ThemedProps {
    selected: boolean;
}

const Item = styled.li.attrs({
    tabIndex: 0,
    role: 'button',
})<AddressItemProps>`
    display: flex;
    flex-flow: column;
    padding: 0.5rem 1em;
    position: relative;
    min-width: 12rem;

    border-top: 0.5px solid ${(props: ThemedProps) => props.theme.colors.lightGray};
    border-bottom: 0.5px solid ${(props: ThemedProps) => props.theme.colors.lightGray};
    background-color: ${({ theme, selected }: AddressItemProps) => (selected ? theme.colors.lightGray : 'transparent')};

    &::before {
        content: '';
        display: block;
        position: absolute;
        transform: translate(-50%, 0);
        left: calc(0.5rem - 2px);
        width: 4px;
        height: calc(100% - 1rem);
        background-color: ${({ theme, selected }: AddressItemProps) =>
            selected ? theme.colors.yellow : theme.colors.gray};
    }

    &:hover {
        cursor: pointer;
        background-color: ${({ theme }: AddressItemProps) => theme.colors.lightGray};
        &::before {
            background-color: ${({ theme }: AddressItemProps) => theme.colors.yellow};
        }
    }

    ${({ theme }: ThemedProps) => theme.mediaQueries.mobile} {
        background-color: ${(props: ThemedProps) => props.theme.colors.white};
        border-radius: ${(props: ThemedProps) => props.theme.borders.radius};
        box-shadow: ${({ theme }: ThemedProps) => theme.effects.shadow};
        margin: 0 0.5rem;

        &::before {
            left: 0.5rem;
        }
    }
`;

const ItemStreet = styled.div`
    font-weight: ${(props: ThemedProps) => props.theme.typography.weights.bold};
`;
interface Props {
    selectedAddressId?: string;
    addresses: Address[];
    setSelectedAddress: (address: Address) => void;
}

const AddressesList: React.FC<Props> = ({ selectedAddressId, addresses, setSelectedAddress }: Props) => {
    return (
        <Container>
            <Heading>Nasze sklepy</Heading>
            <List>
                {addresses.map((address) => (
                    <Item
                        selected={address.id === selectedAddressId}
                        key={address.id}
                        onClick={() => setSelectedAddress(address)}
                    >
                        <ItemStreet>{address.street}</ItemStreet>
                        <span>{address.city}</span>
                        <span>tel. {address.phoneNumber}</span>
                    </Item>
                ))}
            </List>
        </Container>
    );
};

export default AddressesList;
