import React from 'react';
import { Marker as MapboxMarker } from 'react-mapbox-gl';
import styled from 'styled-components';
import IconPin from '../static/icons/icon-pin.inline.svg';
import { ThemedProps } from '../styles/theme';

const CustomIconPin = styled(IconPin).attrs({
    tabIndex: 0,
    role: 'button',
})<ThemedProps>`
    width: 2rem;
    height: 2rem;
    fill: ${(props: ThemedProps) => props.theme.colors.yellow};
    cursor: pointer;
`;

interface Props {
    coordinates: [number, number];
    onClick: (coordinates?: [number, number]) => void;
}

const Marker: React.FC<Props> = ({ coordinates, onClick }: Props) => {
    return (
        <MapboxMarker
            style={{
                zIndex: 0,
            }}
            coordinates={coordinates}
        >
            <CustomIconPin onClick={onClick} />
        </MapboxMarker>
    );
};

export default Marker;
