import React, { useState } from 'react';
import LayoutWithProtection from '../components/layout/layout-with-protection';
import ContactMap from '../components/contact/contact-map/contact-map';
import { graphql } from 'gatsby';
import { useEffect } from 'react';
import ContactForm from '../components/contact/contact-form/contact-form';
export interface Address {
    id: string;
    street: string;
    city: string;
    coordinates: [number, number];
    phoneNumber: string;
    googleUrl: string;
    hours: {
        mon?: [string, string];
        tue?: [string, string];
        wen?: [string, string];
        thu?: [string, string];
        fri?: [string, string];
        sat?: [string, string];
        sun?: [string, string];
    };
}

const mapTempAddressesToAddresses = (tempAddresses: any[]): Address[] => {
    return tempAddresses.map((tempAddress) => {
        return {
            ...tempAddress.data,
            id: tempAddress.id,
        } as Address;
    });
};

const Contact: React.FC<any> = ({
    data: {
        cms: { addresses: fetchedAddresses },
    },
}) => {
    const [addresses, setAddresses] = useState<Address[]>([]);

    useEffect(() => {
        const tempAddresses = mapTempAddressesToAddresses(fetchedAddresses);
        setAddresses(tempAddresses);
    }, []);

    return (
        <LayoutWithProtection>
            <ContactForm />
            <ContactMap addresses={addresses} />
        </LayoutWithProtection>
    );
};

export const query = graphql`
    query addresses {
        cms {
            addresses {
                id
                data
            }
        }
    }
`;

export default Contact;
