import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { ThemedProps } from '../styles/theme';

const DefaultTextAreaContainer = styled.label<ThemedProps>`
    display: inline-block;
    position: relative;
    width: 100%;
`;

const DefaultTextArea = styled.textarea<ThemedProps>`
    height: 100%;
    width: 100%;
    resize: none;
    border-color: ${(props: ThemedProps) => props.theme.colors.gray};
    border-radius: ${(props: ThemedProps) => props.theme.borders.radiusSmall};
    border-width: 1px;
    border-style: solid;
    background-color: ${(props: ThemedProps) => props.theme.colors.white};
    padding: 0.75rem 1rem;
    appearance: none;

    &:focus {
        outline-color: ${(props: ThemedProps) => props.theme.colors.yellow};
    }
`;

const DefaultTextAreaLabel = styled.span<ThemedProps>`
    padding: 0 0.25em;
    font-size: ${(props: ThemedProps) => props.theme.typography.sizes.small};
    color: ${(props: ThemedProps) => props.theme.colors.darkgray};
    position: absolute;
    top: -0.5em;
    left: 0.75em;
    z-index: 1;

    &::before,
    &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        left: 0;
        top: 0.5em;
        background-color: ${(props: ThemedProps) => props.theme.colors.white};
        z-index: -1;
    }
`;

interface Props {
    label?: string;
    value?: string | number;
    onChange?: (value: string) => void;
    placeholder?: string;
    rows?: number;
    required?: boolean;
    name?: string;
}

const TextArea: FunctionComponent<Props> = ({
    label,
    value,
    onChange,
    placeholder,
    rows = 1,
    required = false,
    name,
}) => (
    <DefaultTextAreaContainer>
        {label && <DefaultTextAreaLabel>{label}</DefaultTextAreaLabel>}
        <DefaultTextArea
            value={value}
            onChange={(event) => onChange && onChange(event.target.value)}
            placeholder={placeholder}
            rows={rows}
            required={required}
            name={name}
        />
    </DefaultTextAreaContainer>
);

export default TextArea;
