import React from 'react';
import styled from 'styled-components';
import Input from '../../input';
import TextArea from '../../text-area';
import Button from '../../button';
import Ripple from '../../ripple';
import { ThemedProps } from '../../../styles/theme';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const Container = styled.div<ThemedProps>`
    display: flex;
    flex-flow: row;
`;

const Form = styled.form<ThemedProps>`
    display: grid;
    gap: 1rem;
`;

const FormContainer = styled.div<ThemedProps>`
    flex: 1;
    background-color: ${(props: ThemedProps) => props.theme.colors.white};
    padding: 2rem;

    ${({ theme }: ThemedProps) => theme.mediaQueries.mobile} {
        padding-top: 5rem;
    }
`;

const Title = styled.h1<ThemedProps>`
    margin: 0;
    font-size: ${(props: ThemedProps) => props.theme.typography.sizes.title};
    font-weight: ${(props: ThemedProps) => props.theme.typography.weights.normal};
    color: ${(props: ThemedProps) => props.theme.colors.black};
`;

const Subtitle = styled.p<ThemedProps>`
    margin: 0;
    color: ${(props: ThemedProps) => props.theme.colors.black};
    font-size: ${(props: ThemedProps) => props.theme.typography.sizes.normal};
`;

const Subsubtitle = styled.p<ThemedProps>`
    margin: 0;
    color: ${(props: ThemedProps) => props.theme.colors.black};
    font-size: ${(props: ThemedProps) => props.theme.typography.sizes.small};
`;

const Highlighted = styled.span<ThemedProps>`
    font-size: 1em;
    font-weight: ${({ theme }: ThemedProps) => theme.typography.weights.bold};
    color: ${({ theme }: ThemedProps) => theme.colors.yellow};
`;

const ImageContainter = styled.div`
    flex: 2;

    ${({ theme }: ThemedProps) => theme.mediaQueries.mobileAndTablets} {
        flex: 1;
    }

    ${({ theme }: ThemedProps) => theme.mediaQueries.mobile} {
        display: none;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const EmailLink = styled.a<ThemedProps>`
    color: ${({ theme }: ThemedProps) => theme.colors.darkgray};
    transition: all 0.3s ease-in-out;
    text-decoration: underline;

    &:hover {
        color: ${({ theme }: ThemedProps) => theme.colors.yellow};
    }
`;

const ContactForm: React.FC = () => {
    const image = useStaticQuery(graphql`
        query {
            ok: file(relativePath: { eq: "vape.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 2000, grayscale: true) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);

    return (
        <Container>
            <FormContainer>
                <Form action={`https://formsubmit.co/${process.env.GATSBY_KING_VAPE_EMAIL}`} method="POST">
                    <input type="hidden" name="_subject" value="Nowa wiadomość - KING VAPE!" />
                    <Title>
                        <Highlighted>Napisz</Highlighted> do nas!
                    </Title>
                    <Subtitle>
                        Jeśli potrzebujesz dodatkowych informacji o nas bądź o naszych cenach i produktach nie krępuj
                        się i pisz śmiało! Wiadomość możesz wysłać tu bądź bezpośrednio na adres email&nbsp;
                        <EmailLink href={`mailto:${process.env.GATSBY_KING_VAPE_EMAIL}`}>
                            {process.env.GATSBY_KING_VAPE_EMAIL}
                        </EmailLink>
                    </Subtitle>
                    <Subsubtitle>*możesz znaleźć nas również w social mediach, IG i FB czekają!</Subsubtitle>
                    <Input label="Twój email" type="email" name="email" required />
                    <Input label="Temat" type="text" name="subject" required />
                    <TextArea label="W czym możemy Ci pomóc?" rows={10} name="message" required />
                    <ButtonContainer>
                        <Button type="submit" variant="primary">
                            Wyślij
                            <Ripple variant="primary" />
                        </Button>
                    </ButtonContainer>
                </Form>
            </FormContainer>
            <ImageContainter>
                <Img style={{ height: '100%' }} alt="vape" fluid={image.ok.childImageSharp.fluid} />
            </ImageContainter>
        </Container>
    );
};

export default ContactForm;
